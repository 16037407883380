import React from 'react';
import '../style/index.scss';
import * as JsSearch from 'js-search';
import SortBy from '../components/sortby';
import ProductTile from '../components/productTile';
import Filter from '../components/filter';
import i18next from 'i18next';

let search = '';
let searchTerm = '';
let previousSearchTerm = '';
let previousProduct = '';
let originalProductArray = '';
class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productArray: '',
            pageNumber: 1,
            pageSize: 12,
            showedProducts: [],
            isFilterActive: false
        }
    }

    componentDidMount() {
        search = new JsSearch.Search('id');
        search.sanitizer = new JsSearch.LowerCaseSanitizer();
        search.addIndex('marca');
        search.addIndex('modelo');
        search.addIndex('descripcion');
        search.addIndex('descripcionampliada');
        search.addIndex('idarticulo');

        this.props.data.allDatoCmsProduct.edges.map(({ node: product }) => {            
            return search.addDocument(product);
        });
        originalProductArray = search.search(searchTerm).sort((a, b) => {
            if(a.brand === b.brand) {
                return (a.modelo < b.modelo) ? -1 : (a.modelo > b.modelo) ? 1 : 0;
            } else {
                return (a.brand < b.brand) ? -1 : 1;
            }
        });
        this.setState({
            productArray: originalProductArray
        }, this.setShowedProductsOnScroll);

        window.addEventListener('hashchange', function(e){
            if(searchTerm !== previousSearchTerm) {
                this.searchTermChange();
            }
        })
        window.addEventListener('scroll', () => {
            if(document.querySelectorAll('.search__grid').length > 0 && this.state.productArray.length !== this.state.showedProducts.length) {
                let gridElem = document.querySelector('.search__grid');
                let bottomGridPosition = gridElem.offsetTop + gridElem.offsetHeight;
                var windowBottom = window.scrollY + window.innerHeight;
                if(bottomGridPosition < windowBottom - 100) {
                    this.setShowedProductsOnScroll();
                }
            }
        });
    }

    componentDidUpdate() {
        if(searchTerm !== previousSearchTerm) {
            previousSearchTerm = searchTerm;
            originalProductArray = search.search(searchTerm);
            this.setState({
                productArray: originalProductArray,
                pageNumber: 1,
                showedProducts: []
            }, this.setShowedProductsOnScroll);
        }
    }

    /**
     * @function handleSortByChange
     * @desc this function sort the product array by the selected option
     * 
     * @param {e}
     */
    handleSortByChange = (e) => {
        switch (e.target.value) {
            case "name-asc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => {
                        return (a.descripcion < b.descripcion) ? -1 : 1;
                    })
                });
                break;
            case "name-desc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => {
                        return (a.descripcion > b.descripcion) ? -1 : 1;
                    })
                });
                break;
            case "price-asc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => (a.offerpriceeur > b.offerpriceeur) ? 1 : -1)
                });
                break;
            case "price-desc":
                this.setState({
                    productsArray: this.state.productArray.sort((a, b) => (a.offerpriceeur < b.offerpriceeur) ? 1 : -1)
                });
                break;
            default:
                break;
        }
        this.setState({
            pageNumber: 1,
            showedProducts:[]
        }, this.setShowedProductsOnScroll);
        if (document.querySelector(".sort-by label.active")) document.querySelector(".sort-by label.active").classList.remove('active');
        e.target.parentNode.classList.add('active');
    }

    /**
     * @function resetSortBy
     * @desc this function change the sort by selected into alphabetical order
     */
    resetSortBy = () => {
        if(document.querySelector('.sort-by label.active') !== null) {
            document.querySelector('.sort-by label.active').classList.remove('active');
            document.querySelector('.sort-by input[value="name-asc"]').parentElement.classList.add('active');
        }
    }

    /**
     * @function handleOpenSortModal
     * @desc this function open or close the sort by modal
     */
    handleOpenSortModal = () => {
        if (document.querySelector('.filter__title')) {
            document.querySelector('.filter__title').classList.remove('opend');
            document.querySelector('.filter__title').parentElement.classList.remove('opend');
        }
        if (document.querySelector('.shownelements__title')) {
            document.querySelector('.shownelements__title').classList.remove('opend');
            document.querySelector('.shownelements__title').parentElement.classList.remove('opend');
        }
        document.querySelector('.sort-by__title').classList.toggle('opend');
        document.querySelector('.sort-by__title').parentElement.classList.toggle('opend');
    }

    /**
     * @function handleOpenShowNElementsModal
     * @desc this function open or close the shownelements modal
     */
    handleOpenShowNElementsModal = () => {
        if (document.querySelector('.filter__title')) {
            document.querySelector('.filter__title').classList.remove('opend');
            document.querySelector('.filter__title').parentElement.classList.remove('opend');
        }
        if (document.querySelector('.sort-by__title')) {
            document.querySelector('.sort-by__title').classList.remove('opend');
            document.querySelector('.sort-by__title').parentElement.classList.remove('opend');
        }
        document.querySelector('.shownelements__title').classList.toggle('opend');
        document.querySelector('.shownelements__title').parentElement.classList.toggle('opend');
    }

    /**
     * @function handleSortByChange
     * @desc this function make the pagination of the product array
     * 
     * @param {e}
     */
    handleFilter = (prodArray) => {
        this.setState({
            productArray: prodArray,
            pageNumber: 1,
            showedProducts: []
        }, this.setShowedProductsOnScroll);
    }

    setIsFilterActive = (option) => {
        this.setState({
            isFilterActive: option
        });
    }

    /**
     * @function setShowedProductsOnScroll
     * @desc this function show more products when scroll
     */
    setShowedProductsOnScroll = () => {
        let count = 0;
        let numberProductShowed = this.state.pageNumber * this.state.pageSize;
        let sliceNumber = 0;

        if(this.state.showedProducts.length < this.state.productArray.length || this.state.pageNumber === 1) {
            for(var product = 0; product < this.state.productArray.length; product++) {
                if(product > 0) {
                    let previousProduct = this.state.productArray[product - 1];
                    let currentProduct = this.state.productArray[product];
                    if(previousProduct.marca !== currentProduct.marca || previousProduct.modelo !== currentProduct.modelo) {
                        count++
                    }
                } else {
                    count++;
                }
                if(count === numberProductShowed || product + 1 === this.state.productArray.length) {
                    sliceNumber = product + 1;
                    break;
                }
            }
            this.setState({
                showedProducts: this.state.productArray.slice(0, sliceNumber),
                pageNumber: this.state.pageNumber + 1
            });
        }
    }

    render() {
        const data = this.props.data;
        previousProduct = '';
        searchTerm = this.props.searchText;
        if(searchTerm !== previousSearchTerm) {
            this.resetSortBy();            
        }
        if(this.state.productArray.length !== 0 && Array.isArray(this.state.showedProducts)) {
            return(
                <div className="search">
                    <div className="search__title">
                        <h1>{i18next.t('SEARCH_TITLE')}</h1>
                    </div>
                    <div className="search__filters-sort">
                        {(this.state.productArray.length > 1 || this.state.isFilterActive) &&
                            <Filter 
                                productArray={this.state.productArray}
                                handleFilter={this.handleFilter}
                                setIsFilterActive={this.setIsFilterActive}
                                searchTerm={searchTerm}
                                locale={i18next.language}
                                newBadgeDays={data.newBadgeDays} 
                            />
                        }
                        <SortBy handleOpenSortModal={this.handleOpenSortModal} handleSortByChange={this.handleSortByChange}></SortBy>
                    </div>
                    <div className="search__wrapper">            
                    {
                        this.state.showedProducts.length > 0 ?
                            <div className="search__grid">
                                {
                                    typeof this.state.showedProducts === 'undefined' ? '' :
                                    this.state.showedProducts.map((product, index) => {
                                        if(!previousProduct || product.marca !== previousProduct.marca || product.modelo !== previousProduct.modelo){
                                            previousProduct = product;
                                            return (
                                                <ProductTile key={index} locale={product.locale} product={product} data={data}></ProductTile>                              
                                            );
                                        } else {
                                            return '';
                                        }
                                    })
                                }
                            </div>
                        :
                        <div className="search__not-found">
                            <span>{i18next.t("FILTER_NO_PRODUCTS_FOUND")}</span>
                        </div>
                    }
                    </div>
                </div>
            );
        } else {
            return (
                <div className="search">
                    <div className="search__title">
                        <h1>{i18next.t('SEARCH_TITLE')}</h1>
                    </div>
                    <div className="search__wrapper">  
                        <div className="search__not-found">
                            <span>{i18next.t("SEARCH_NO_PRODUCTS_FOUND")} "{searchTerm}"</span>
                        </div>
                    </div>
                </div>
            
            );
        }
    }
}

export default Search