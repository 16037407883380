import React from 'react'
import Layout from "../layouts/index";
import '../style/index.scss';
import { graphql } from 'gatsby';
import { GlobalContext } from '../../../frontend/src/context/globalContext';
import Search from '../components/search';

class SearchPage extends React.Component {
    render() {
        return (
            <Layout data={this.props.data}>
                <GlobalContext.Consumer>
                   {state => {
                        var url = ((typeof window !== 'undefined' && window.location) ? new URL(window.location.href) : null);
                        let searchText = url ? url.searchParams.get("term") : "";
                        if(state.searchText) {
                            searchText = state.searchText;
                        }
                        return <Search searchText={searchText} data={this.props.data} />;
                    }} 
                </GlobalContext.Consumer>
            </Layout>
        )
    }
}

export default SearchPage

export const query = graphql`
query ($locale: String!) {  
    categories: allDatoCmsCategory(sort: {order: ASC, fields: sequence}, filter: {top: {eq: true}, locale: {eq: $locale}}) {
        edges {
            node {
                id
                name
                url
                sequence
                locale
                subcategory {
                    id
                    name
                    url
                    locale
                    sequence
                    subcategory {
                      id
                      name
                      url
                      locale
                      sequence
                    }
                }
            }
        }
    }
    contentPages: allDatoCmsContentPage(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                locale
                url
                cpContentGridTitle
            }
        }
    }
    allDatoCmsProduct(filter: {locale: {eq: $locale}}, sort: {fields: modelo}) {
        edges {
            node {
                id
                descripcion
                descripcionampliada
                locale
                categoria
                categorias {
                    id
                    name
                    url
                    locale
                    sequence
                }
                marca
                modelo
                colorcrist
                colormont
                forma
                material
                sexo
                polarizacion
                calibre
                puente
                varilla
                idarticulo
                images
                weight
                offerpriceeur
                offerpricegbp
                offerpriceusd
                listpriceeur
                listpricegbp
                listpriceusd
                crosssell
                meta {
                  createdAt
                }
            }
        }
    }
    allDatoCmsFooter(filter: {locale: {eq: $locale}}) {
        edges {
            node {
                footerLinksList
                footerCopyright
                footerPayments
            }
        }
    }
    contactinfo : datoCmsExtrainfo(name: {eq: "contactinfo"}, locale: {eq: $locale}) {
        data
    }
    newBadgeDays : datoCmsExtrainfo(name: {eq: "NewBadge_Constant"}, locale: {eq: $locale}) {
        data
    }
    ddm : allDatoCmsDdm(filter: {locale: {eq: $locale}}) {
        nodes {
            submenu {
                header
                directLink
                menu
            }
        }
    }
}
`